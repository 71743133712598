/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { Typography } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable, { OverlayLoading } from 'material-table';
import styles from './ServiceRequests.module.css';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from 'Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from 'Util/Property/GeneralProperties';
import { serviceRequestProperties,getExportServiceRequestForm } from "Util/Property/ServiceRequestProperties";
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import LoadingOverlay from 'react-loading-overlay';
import {
  deleteServiceRequest,
  getEstimatedTimeForPlannedDate,
} from 'services/ServiceRequest/ServiceRequestService';
import { getAllUsers , getUser } from 'services/User/UserService';
import { Link } from 'react-router-dom';
import { getCustomer } from 'services/Customer/CustomerService';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import { handleErrorMessage } from 'services/CommonService';
import * as moment from 'moment';
import { buildFilterObject } from '../../Util/Util';
import UrlProperties from 'Util/Property/UrlProperties';
import RouterProps from '../../Util/Property/RouterProperties';
import Spinner from 'components/Spinner/Spinner';
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FullWidthTabPanel from "components/TabPanels/FullWidthTabPanel";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ServiceRequestsExport from "./ServiceRequestsExport";


const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "#e6e4df",
    height: "10px",
    top: "45px",
  },
  tabsWrapper: {
    background: "#e6e4df",
  },
}));

const ServiceRequests = (props) => {
  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [assignValues, setAssignValues] = useState({
    estimatedTimeHours: 0,
    estimatedTimeMinutes: 0,
    offsetTimeHours: 0,
    offsetTimeMinutes: 0,
    totalEstimatedTimeHours: 0,
    totalEstimatedTimeMinutes: 0,
    totalTimeInMinutes: 0,
    assignToOptions: [],
    assignTo: '',
    scheduledDate: null,
    assignUserDetails: {
      jobCount: null,
      totalHours: null,
      messageAlert: '',
    },
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState({
    page: 1,
    pageSize: 5,
  });
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveCustomer, setIsActiveCustomer] = useState(false);
  const [viewMap, setViewMap] = useState(true);
  const [value, setValue] = useState(0);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [isExportDialogLoading, setIsExportDialogLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const DISTRIBUTOR_TYPE_USER = "DISTRIBUTOR"
  const CBL_TYPE_USER = "USER"

  const [exportServiceRequestForm, setexportServiceRequestForm] = useState(
    getExportServiceRequestForm()
  );
  const [requestTypes, setRequestTypes] = useState([]);

  const exportDialogOpenHandler = () => {
    setIsExportDialogOpen(true);
  };

  const notyfyExportStatus = (status) => {
    notyDefault({
      type: notyTypes.INFORMATION,
      text: status,
    });
  }
  
  const srTableRef = useRef(null);
  useEffect(() => {
    let customerId = 0;

    if (
      window.location.pathname ==
      RouterProps.mainDomain + '/customer-dashboard'
    ) {
      setViewMap(false);
    }

    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }

    if (props.customerId && props.customerId > 0) {
      customerId = props.customerId;
    } else if (props.id && props.id > 0) {
      customerId = props.id;
    } else if (props.location) {
      if (typeof queryParam['id'] != 'undefined') {
        customerId = queryParam['id'];
      }
    }
    let permissionArray = [
      PermissionProperties.addServiceRequest,
      PermissionProperties.viewServiceRequest,
      PermissionProperties.editServiceRequest,
      PermissionProperties.deleteServiceRequest,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    setSelectedCustomerId(customerId);
    if (customerId > 0) {
      getCustomerForCheck(customerId);
    }
    getAllUsersForServiceRequest();
  }, []);

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCustomerForCheck = (custId) => {
    getCustomer(custId)
      .then((result) => {
        if (result.status === 200) {
          if (result.data.status === 'ACTIVE') {
            setIsActiveCustomer(true);
          }
        } else {
          handleErrorMessage(
            result.data,
            customerProperties.messages.error.loadData
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, customerProperties.messages.error.loadData);
      });
  };

  const deleteServiceRequestDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteServiceRequestDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    let data = { ...deleteRowData };
    deleteServiceRequest(data.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: serviceRequestProperties.messages.success.delete,
          });
          srTableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.delete,
        });
      });

    deleteServiceRequestDialogCloseHandler();
  };

  const deleteServiceRequestHandler = (rowData) => {
    setDeleteRowData(rowData);
    deleteServiceRequestDialogOpenHandler();
  };

  const onServiceRequestEditHandler = (rowData, isEdit) => {
    let url = props.match.url;
    if (selectedCustomerId > 0) {
      url += "/serviceRequests/serviceRequest";
    } else {
      url += "/serviceRequest";
    }
    props.history.push({
      pathname: url,
      selectedSr: rowData,
      search:
        "?id=" +
        rowData.id +
        "&isEdit=" +
        isEdit +
        "&customerId=" +
        selectedCustomerId,
    });
  };

  const getAllUsersForServiceRequest = async () => {
    let allUsers = localStorage.getItem('allUsers');
    if (!allUsers) {
      getAllUsers()
        .then((result) => {
          if (result.status === 200) {
            let userOptions = [];
            result.data.map((user) => {
              const area = user.area != null ? ' (' + user.area + ')' : null;
              const displayValue =
                area != null
                  ? user.first_name + ' ' + user.last_name + area
                  : user.first_name + ' ' + user.last_name;
              userOptions.push({
                value: user.id,
                displayValue: displayValue,
              });
            });

            let assignValueData = { ...assignValues };
            assignValueData.assignToOptions = userOptions;
            setAssignValues(assignValueData);
          } else {
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : serviceRequestProperties.messages.error.loadUsers,
            });
          }
        })
        .catch((error) => {
          notyDefault({
            type: notyTypes.ERROR,
            text: serviceRequestProperties.messages.error.loadUsers,
          });
        });
    } else {
      let userOptions = [];
      JSON.parse(allUsers).map((user) =>
        userOptions.push({
          value: user.id,
          displayValue: user.first_name + ' ' + user.last_name,
        })
      );

      let assignValueData = { ...assignValues };
      assignValueData.assignToOptions = userOptions;
      setAssignValues(assignValueData);

      const result = await getAllUsers();

      if (result.status === 200 && result.data?.length > 0) {
        localStorage.setItem('allUsers', JSON.stringify(result.data));
      }
    }
  };

  const rowSelectionChangeHandler = (rows) => {
    setSelectedRows(rows);
  };

  useEffect(() => {
    getAssignUserDetails(assignValues);
  }, [assignValues.assignTo, assignValues.scheduledDate]);

  const getAssignUserDetails = (assignValueData) => {
    const { assignTo, scheduledDate } = assignValueData;
    if (assignTo && scheduledDate) {
      const scheduledDateClone = { ...scheduledDate };
      const formattedScheduledDate = moment(scheduledDateClone).format(
        'YYYY-MM-DD'
      );
      getEstimatedTimeForPlannedDate(assignTo, formattedScheduledDate)
        .then((result) => {
          if (result.status === 200) {
            let existingAssignValues = { ...assignValues };
            existingAssignValues.assignUserDetails = {
              jobCount: result.data.totalJobCount,
              totalHours: result.data.totalEstimatedTime,
              messageAlert: result.data.messageAlert || '',
            };
            setAssignValues(existingAssignValues);
          } else {
            console.log(result);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onPaginationProperyChange = (val, key) => {
    let tData = { ...tableData };
    tData[key] = val;
    setTableData(tData);
  };

  const setDataToExportServiceRequestForm = (
    key,
    options,
    isFirstTime,
    optionValue
  ) => {
    let formData = exportServiceRequestForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (formData.elType == 'date') {
      inputProps.value = new Date()
      formData.inputProps = inputProps;
      exportServiceRequestForm[key] = formData;
      return
    }

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else if (optionValue !== undefined) {
      defaultValue = optionValue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    exportServiceRequestForm[key] = formData;
    return defaultValue;
  };

  const srTable = (statusFilter, statusExcludeFilter) => {
    return (
      <div className={'material-grid-wrapper'}>
      <MaterialTable
        title={
          <div>
            <span className={'grid-title-icon'}>
              <AssignmentTurnedInIcon
                style={{ fontSize: '1.2rem' }}
              />
            </span>
            <Typography
              variant="h6"
              style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
            >
              {serviceRequestProperties.serviceRequests}
            </Typography>
          </div>
        }
        tableRef={srTableRef}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Type', field: 'requestTypeName' },
          {
            title: 'Outlet',
            field: 'customerName',
            cellStyle: {
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
          {
            title: 'Address',
            field: 'address',
            cellStyle: {
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            emptyValue: () => <div>-</div>,
            render: (rowData) => (
              <OverflowTooltip
                tooltip={rowData.address}
                text={rowData.address}
                placement="top"
                style={{ width: '100%' }}
              />
            ),
          },
          {
            title: 'Stage',
            field: 'stage_name',
            filterField: 'stage_filter',
          },
          {
            title: 'Assigned To',
            field: 'assignedTo',
            render: (rowData) =>
              rowData.assignedTo ? rowData.assignedTo : '-',
          },
          {
            title: 'Planned Completion',
            field: 'planned_completion_date',
            filterField: 'planned_completion_date_filter',
            render: (rowData) =>
              rowData.createdBy
                ? moment(rowData.planned_completion_date).format(
                    'YYYY/MM/DD'
                  )
                : '-',
          },
          {
            title: 'Added By',
            field: 'Added By',
            filterField: 'Added By',
            render: (rowData) =>
              rowData.createdBy ? rowData.createdBy : '-',
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            const filterDto = buildFilterObject(query);
            if (selectedCustomerId > 0) {
              filterDto['customer_id'] = selectedCustomerId;
            }
            if (props.id > 0 && props.customerId > 0) {
              filterDto['product_id'] = props.id;
            }
            let url =
              urlProperties.baseUrl +
              urlProperties.serviceRequest.filterServiceRequest +
              '?';
            url += 'perPage=' + query.pageSize;
            url += '&page=' + query.page;
            url +=
              '&sortColumn=' +
              (typeof query.orderBy === 'undefined'
                ? 'id'
                : query.orderBy.field);
            url += '&sortOrder=' + query.orderDirection;
            url += '&search=' + query.search;
            filterDto['user_role_id'] = localStorage.getItem(
              'role'
            );
            if (statusFilter) {
              filterDto["status_filter"] = statusFilter;
            }
            if (statusExcludeFilter) {
              filterDto["status_exclude_filter"] = statusExcludeFilter;
            }
            fetch(url, {
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify(filterDto),
            })
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page,
                  totalCount: result.total,
                });
              });
          })
        }
        components={{
          OverlayLoading: () => (
            <div className={styles.loadingOverlay}>
              <Spinner />
            </div>
          ),
        }}
        actions={[
          {
            icon: 'visibility',
            tooltip: 'View More Details',
            iconProps: { className: 'action-buttons view-button' },
            onClick: (event, rowData) =>
              onServiceRequestEditHandler(rowData, false),
            position: 'row',
            hidden: false
          },
          // {
          //   icon: 'edit',
          //   tooltip: 'Edit Service Request',
          //   iconProps: { className: 'action-buttons edit-button' },
          //   onClick: (event, rowData) =>
          //     onServiceRequestEditHandler(rowData, true),
          //   position: 'row',
          //   hidden:true
          // },
          {
            icon: 'delete',
            tooltip: 'Delete Service Request',
            iconProps: {
              className: 'action-buttons delete-button',
            },
            onClick: (event, rowData) =>
              deleteServiceRequestHandler(rowData),
            position: 'row',
            hidden:
              !permissions[
                PermissionProperties.deleteServiceRequest
              ] ||
              (selectedCustomerId > 0 && !isActiveCustomer),
          },
          {
            icon: () => (
              <Link
                to={
                  props.id > 0 && props.customerId > 0
                    ? props.match.url +
                      '/serviceRequests/serviceRequest?productId=' +
                      props.id +
                      '&customerId=' +
                      props.customerId
                    : selectedCustomerId > 0
                    ? props.match.url +
                      '/serviceRequests/serviceRequest?customerId=' +
                      selectedCustomerId
                    : props.match.url + '/serviceRequest'
                }
              >
                <div
                  className={
                    'generic-button-class div-button-wrapper'
                  }
                  style={{ borderRadius: '0.5rem' }}
                  variant="contained"
                  color="primary"
                >
                  {' '}
                  {serviceRequestProperties.addServiceRequest}
                </div>
              </Link>
            ),
            tooltip: 'Add New Service Request',
            isFreeAction: true,
            hidden:true
          },
          {
            icon: () => (
                <div
                  className={
                    "generic-button-class div-button-wrapper"
                  }
                  style={{ borderRadius: "0.5rem" }}
                  variant="contained"
                  color="primary"
                  onClick={exportDialogOpenHandler}
                >
                  {" "}
                  {"EXPORT"}
                </div>
            ),
            tooltip: "EXPORT SR",
            isFreeAction: true,
            hidden:false
          },
        ]}
        options={{
          columnsButton: true,
          actionsColumnIndex: -1,
          selection: false,
          tableLayout: 'fixed',
          pageSize: tableData.pageSize,
          filtering: true,
          sorting: false,
          search: false,
          maxBodyHeight: '70vh',
          headerStyle: {
            fontSize: '0.7rem',
            textAlignL: 'center',
            backgroundColor: '#e6e4df',
          },
          rowStyle: {
            fontSize: '0.6rem',
            fontWeight: 450,
            padding: '5rem',
            lineHeight: 100,
          },
        }}
        onSelectionChange={(rows) =>
          rowSelectionChangeHandler(rows)
        }
        onChangeRowsPerPage={(pageSize) =>
          onPaginationProperyChange(pageSize, 'pageSize')
        }
        onChangePage={(page, pageSize) =>
          onPaginationProperyChange(page, 'page')
        }
      />
    </div>
    )
  }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteServiceRequestDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {
                        serviceRequestProperties.messages.normal
                          .deleteServiceRequest
                      }
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteServiceRequestDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* export dialog content */}
                 <ServiceRequestsExport 
                  exportServiceRequestForm={exportServiceRequestForm} 
                  setexportServiceRequestForm={setexportServiceRequestForm}
                  isExportDialogOpen={isExportDialogOpen}
                  setIsExportDialogOpen={setIsExportDialogOpen}
                  setDataToExportServiceRequestForm={setDataToExportServiceRequestForm}
                  notyfyExportStatus={notyfyExportStatus}
                />
                <AppBar
                  position="static"
                  color="inherit"
                  className={styles.appBarStyle}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    className={classes.tabsWrapper}
                  >
                    <Tab label={"Not Completed"} {...a11yProps(0)} />
                    <Tab label={"Completed"} {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <FullWidthTabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  {srTable(null, "Closed")}
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  {srTable("Closed", null)}
                </FullWidthTabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ServiceRequests;
